'use strict';

/*******************************************************************************************/
class UxrEeRadioGroup extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = false;
		this.state = {
			'value': this.props.value || this.props.defaultValue || this.defaultValue,
		};
		
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let children = this.props.options.map((each) => (
			<div key={each.value}>
				<UxrEe_.RadioGroup.Radio value={each.value} />
				<label>{each.label}</label>
			</div>
		));
		
		return (
			<div {...this.props.htmlProps}>
				<UxrEe_.RadioGroup.RadioGroup selectedValue={this.state.value} onChange={this.onChange}>
					{children}
				</UxrEe_.RadioGroup.RadioGroup>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(value) {
 		this.setState({
			'value': this.props.value
		});
		this.props.onChange(this, {value});
	}
	
} //class
